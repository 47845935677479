import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ITrip } from '@app/pages/dashboard/shared/interfaces/trip.interface';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { IPaging } from '@app/shared/interfaces/paging.interface';

@Injectable({
  providedIn: 'root',
})
export class TripService {
  private trips$: BehaviorSubject<ITrip[]>;
  public trips: Observable<ITrip[]>;

  private tripsLoading$: BehaviorSubject<boolean>;
  public tripsLoading: Observable<boolean>;

  private tripsPaging$: BehaviorSubject<IPaging>;
  public tripsPaging: Observable<IPaging>;

  private approveLoading$: BehaviorSubject<boolean>;
  public approveLoading: Observable<boolean>;

  private cancelLoading$: BehaviorSubject<boolean>;
  public cancelLoading: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private userAuthenticationService: AuthService
  ) {
    this.trips$ = new BehaviorSubject<ITrip[]>(null);
    this.trips = this.trips$.asObservable();

    this.tripsLoading$ = new BehaviorSubject<boolean>(false);
    this.tripsLoading = this.tripsLoading$.asObservable();

    this.tripsPaging$ = new BehaviorSubject<IPaging>(null);
    this.tripsPaging = this.tripsPaging$.asObservable();

    this.approveLoading$ = new BehaviorSubject<boolean>(false);
    this.approveLoading = this.approveLoading$.asObservable();

    this.cancelLoading$ = new BehaviorSubject<boolean>(false);
    this.cancelLoading = this.cancelLoading$.asObservable();
  }

  getAll(filterPayload) {
    const { status, currentTime } = filterPayload;
    delete filterPayload.status;
    delete filterPayload.currentTime;

    const currentUser = this.userAuthenticationService.authValue;
    const filterParams = Object.entries(filterPayload).reduce(
      (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
      {}
    );

    const params = new HttpParams({ fromObject: filterParams }).toString();


    return this.http
      .post<ITrip[]>(
        `${environment.apiUrl}/api/v1/operators/${currentUser.operator_id}/quotes/trips?${params}`,
        { status, currentTime }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            this.trips$.next(response.data);

            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data,
            };

            this.tripsPaging$.next(paging);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  getAllHistory(filterPayload) {
    const { status } = filterPayload;
    delete filterPayload.status;

    const currentUser = this.userAuthenticationService.authValue;
    const filterParams = Object.entries(filterPayload).reduce(
      (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
      {}
    );
    const params = new HttpParams({ fromObject: filterParams }).toString();

    return this.http
      .post<ITrip[]>(
        `${environment.apiUrl}/api/v1/operators/${currentUser.operator_id}/quotes/trips?${params}&mode=1`,
        { status }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            this.trips$.next(response.data);

            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data,
            };

            this.tripsPaging$.next(paging);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  scheduleAutoClose(quoteId: number, end: string) {
    let d = new Date(end);
    d.setDate(d.getDate() + 3);

    const context = {
      quoteId,
      end: d.valueOf(),
    };

    return this.http
      .post(`${environment.apiPaymentUrl}/schedule-auto-close`, context)
      .subscribe((r: any) => console.log(r));
  }

  charge(quoteId) {
    this.http
      .post(`${environment.apiPaymentUrl}/charge-payment`, {
        quoteId,
      })
      .subscribe();
  }

  chargeCustomer(quoteId: number) {
    return new Promise((resolve, reject) => {

      this.http.post(`${environment.apiPaymentUrl}/charge-payment`, { quoteId: quoteId }).subscribe((dt: any) => {

        if (dt.message === "Success") {
          resolve(dt);
        }
        else {
          reject(dt);
        }

      });

    });



  }
  approveBooking(quoteId: number) {
    return this.http
      .post<ITrip[]>(
        `${environment.apiUrl}/api/v1/operators/quotes/${quoteId}/approve`,
        {}
      )
      .pipe(
        map((response: any) => {

          if (response) {
            const trips = this.trips$.value;
            if (trips) {
              const position = trips.findIndex(
                (trip) => trip.quote_id == quoteId
              );

              if (position > -1) {
                trips[position] = {
                  ...trips[position],
                  quote_status: 'approved',
                };

                this.trips$.next(trips);
              }
            }

            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  cancelBooking(quoteId: number) {
    return this.http.post(
      `${environment.apiUrl}/api/v1/operators/quotes/${quoteId}/cancel`,
      {}
    );

    // return this.http.post<ITrip[]>(`${environment.apiUrl}/api/v1/operators/quotes/${quoteId}/cancel`, {})
    //   .pipe(
    //     map((response: any) => {
    //       if (response) {
    //         this.trips$.next(response.data);
    //         return response;
    //       }

    //       throw new Error(response.errorMessage);
    //     })
    //   );
  }
}
