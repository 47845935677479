import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ICustomer } from '@app/pages/customer/shared/interfaces/customer.interface';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { IPaging } from '@app/shared/interfaces/paging.interface';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private cust$: BehaviorSubject<ICustomer[]>;
  public cust: Observable<ICustomer[]>;

  private custDetail$: BehaviorSubject<ICustomer[]>;
  public custDetail: Observable<ICustomer[]>;

  private custPaging$: BehaviorSubject<IPaging>;
  public custPaging: Observable<IPaging>;

  private custDetailPaging$: BehaviorSubject<IPaging>;
  public custDetailPaging: Observable<IPaging>;

  constructor(
    private http: HttpClient,
    private userAuthenticationService: AuthService
  ) {
    this.cust$ = new BehaviorSubject<any>(null);
    this.cust = this.cust$.asObservable();

    this.custPaging$ = new BehaviorSubject<IPaging>(null);
    this.custPaging = this.custPaging$.asObservable();

    this.custDetail$ = new BehaviorSubject<any>(null);
    this.custDetail = this.custDetail$.asObservable();

    this.custDetailPaging$ = new BehaviorSubject<IPaging>(null);
    this.custDetailPaging = this.custDetailPaging$.asObservable();
  }

  getAll(filterPayload) {
    const currentUser = this.userAuthenticationService.authValue;

    const filterParams = Object.entries(filterPayload).reduce((a, [k, v]) => (v === null ? a : (a[k] = v, a)), {})
    const params = new HttpParams({ fromObject: filterParams }).toString();

    return this.http.post<ICustomer[]>(`${environment.apiUrl}/api/v1/operators/${currentUser.operator_id}/customers-list?${params}`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.cust$.next(response.data);
            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data
            }

            this.custPaging$.next(paging);

            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  getDetailAll(filterPayload, id) {
    const currentUser = this.userAuthenticationService.authValue;

    const filterParams = Object.entries(filterPayload).reduce((a, [k, v]) => (v === null ? a : (a[k] = v, a)), {})
    const params = new HttpParams({ fromObject: filterParams }).toString();

    return this.http.post<ICustomer[]>(`${environment.apiUrl}/api/v1/operators/customer-trips/${id}?${params}`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.custDetail$.next(response.data);
            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data
            };

            this.custDetailPaging$.next(paging);

            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }
}
